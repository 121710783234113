<template>
  <div class="menu" v-if="loading === true">
    <div id="nav">
      <b-container>
        <div>
          <b-row>
            <b-col class="col-md-8 col-sm-12 mobileBanners">
              <!-- <a :href="home.acf.banner_one_url"> -->
              <!-- <b-img
                :class="[isActive ? 'show' : 'hide']"
                :src="home.acf.banner_one_homepage"
              ></b-img>
              </a>
              <template v-if="home.acf.banner_two_homepage === true">
              <a :href="home.acf.banner_two_url">
              <b-img
                :class="[isActive ? 'show' : 'hide']"
                :src="home.acf.banner_two_homepage"
              ></b-img>
              </a> -->
              <!-- </template> -->
              <b-carousel
                id="carousel-fade"
                style="text-shadow: 0px 0px 2px #000"
                fade
                img-width="1024"
                img-height="480"
              >
              <a :href="home.acf.banner_one_url" target="_blank">
                <b-carousel-slide
                  :img-src="home.acf.banner_one_homepage"
                >
                </b-carousel-slide>
              </a>
              <a :href="home.acf.banner_two_url" v-if="home.acf.banner_two_homepage.length > 0" target="_blank">
                <b-carousel-slide
                  v-if="home.acf.banner_two_homepage.length > 0"
                  :to="home.acf.banner_two_url"
                  :img-src="home.acf.banner_two_homepage"
                ></b-carousel-slide>
              </a>
              <a :href="home.acf.banner_three_url" v-if="home.acf.banner_three_homepage.length > 0" target="_blank">
                <b-carousel-slide
                  v-if="home.acf.banner_three_homepage.length > 0"
                  :to="home.acf.banner_three_url"
                  :img-src="home.acf.banner_three_homepage"
                ></b-carousel-slide>
              </a>
              </b-carousel>
            </b-col>
            <b-col class="col-md-4 col-sm-12 ipadbelow">
              <b-embed
                type="iframe"
                aspect="21by9"
                src="https://static.rolex.com/clocks/2019/asian_tour_v1_2_HTML_300x84/rolex.html"
                allowfullscreen
              ></b-embed>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "adBanner",
  props: ["page"],
  data() {
    return {
      menueLink: "menuLink",
      isActive: [],
      home: [],
      loading: ''
    };
  },
  methods: {
    toggleClass: function() {
      if (this.home.acf.banner_two_homepage === true)
        this.isActive = !this.isActive;
      else this.isActive;
    }
  },
  created() {
    setInterval(this.toggleClass, 1000);
  },
  mounted() {
    axios
      .get(this.page)
      .then(response => {
        this.home = response.data;
        this.loading = true;
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 990px) {
  .mobileBanners {
    min-width: 100%;
    margin-top: 0em;
    margin-bottom: 1em;
  }
  .show {
    display: block;
    width: 100%;
    /* height: 65%; */
    margin-bottom: 2em;
  }
  .embed-responsive {
    height: 36%!important;
  }
  .ipadbelow {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .embed-responsive {
    height: 60%!important;
  }
}

.menu {
  background-color: white;
}

.show {
  display: block;
  width: 100%;
  height: 65%;
}
.hide {
  display: none;
}
.embed-responsive {
  height: 65%;
}
</style>
